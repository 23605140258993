import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import EnvContext from './EnvContext';

const PaymentContext = createContext();

export const PaymentContextProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const [transactions, setTransactions] = useState([]);
  const initiateThePayment = async data => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const postData = {
      isSubscription: data.isSubscription,
      subscriptionPlanId: data.subscriptionPlanId,
      planDuration: data.planDuration,
      isRenewal: data.isRenewal,
      courseIds: data.courseIds,
      userId: data.userId,
      merchantTransactionId: data.merchantTransactionId,
      couponCode: data.couponCode,
    };

    try {
      await axios
        .post(`${backendUrl}/user/transaction/pay`, postData, {
          headers: headers,
        })
        .then(res => {
          window.location.href = res.data;
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllTransactions = async userId => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const url = userId
      ? `${backendUrl}/user/transaction${
          role === 'admin' ? `?userId=${userId}` : `/`
        }`
      : `${backendUrl}/user/transaction/all`;

    try {
      const res = await axios.get(url, { headers: headers });
      setTransactions(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PaymentContext.Provider
      value={{ initiateThePayment, fetchAllTransactions, transactions }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContext;
