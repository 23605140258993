import { createContext, useContext, useEffect, useState } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
// import toast, { Toaster } from 'react-hot-toast';
import { useToast } from '@chakra-ui/react';

const CouponContext = createContext();
let loadingToast;

export const CouponContextProvider = ({ children }) => {
  const toast = useToast();

  const [token, setToken] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [couponData, setCouponData] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [spninnerStatus, setShowSpinnerStatus] = useState(false);

  const testing = () => {
    setTimeout(() => {
      setShowSpinnerStatus(!spninnerStatus);
    }, 5000);
  };
  // useEffect(() => {
  //   testing();

  // }, [showSpinner, spninnerStatus]);
  const { backendUrl } = useContext(EnvContext);
  const getCoupon = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const getCouponPromise = axios.get(`${backendUrl}/coupon/all`, { headers });

    toast.promise(getCouponPromise, {
      loading: {
        title: 'Fetching coupon...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Coupon fetched successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to fetch coupon!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await getCouponPromise;
      setCoupons(res.data.data);
      toast.success(res.data.message, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const addCoupon = async postData => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const addCouponPromise = axios.post(`${backendUrl}/coupon`, postData, {
      headers: headers,
    });

    toast.promise(addCouponPromise, {
      loading: {
        title: 'Adding coupon ...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Coupon added successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add coupon!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      await addCouponPromise;

      getCoupon();
    } catch (err) {
      console.error(err);
    }
  };

  const getAboutCoupon = async code => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/coupon/one?code=${code}`, { headers: headers })
        .then(res => {
          setCouponData(res.data.data);
          setShowSpinnerStatus(true);
          setShowSpinner(false);
        })
        .catch(err => {
          setShowSpinnerStatus(false);
          setShowSpinner(false);
        });
    } catch (err) {
      console.error(err);
      setShowSpinnerStatus(false);
      setShowSpinner(false);
    }
  };

  const updateCoupon = async postData => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const updateCouponPromise = axios.put(
      `${backendUrl}/coupon?discountId=${couponId}`,
      postData,
      { headers: headers }
    );

    toast.promise(updateCouponPromise, {
      loading: {
        title: 'Updating coupon...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Coupon updated successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to update coupon!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await updateCouponPromise;

      getCoupon();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteCoupon = async couponId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const deleteCouponPromise = axios.delete(
      `${backendUrl}/coupon?discountId=${couponId}`,
      { headers: headers }
    );

    toast.promise(deleteCouponPromise, {
      loading: {
        title: 'Deleting coupon ...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Coupon deleted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to delete coupon!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await deleteCouponPromise;

      getCoupon();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <CouponContext.Provider
      value={{
        addCoupon,
        getCoupon,
        coupons,
        setCouponId,
        couponId,
        getAboutCoupon,
        couponData,
        showSpinner,
        setShowSpinner,
        spninnerStatus,
        updateCoupon,
        deleteCoupon,
        setCouponData,
      }}
    >
      {children}
    </CouponContext.Provider>
  );
};

export default CouponContext;
