import React, { createContext, useContext, useEffect, useState } from 'react';
import CourseContext from './CourseListContext';
import EnvContext from './EnvContext';
// import toast from 'react-hot-toast';
import axios from 'axios';
import ContentContext from './ContentContext';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

const ProjectContext = createContext();
export const ProjectContextProvider = ({ children }) => {
  const toast = useToast();

  const { fetchUserScoreboard } = useContext(ContentContext);
  const [token, setToken] = useState(null);
  const { backendUrl } = useContext(EnvContext);
  const { scorecard } = useContext(ContentContext);
  const [projectId, setProjectId] = useState(null);
  const [ProjectData, setProjectData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [latestSubProjectId, setLatestSubProjectId] = useState(null);
  const [submittedProjects, setSubmittedProject] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const userId = localStorage.getItem('userId');
  const courseId = localStorage.getItem('courseId');
  const naviagte = useNavigate();

  const [projects, setProjects] = useState([]);

  const fetchAllProjects = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Project List is loading', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .get(`${backendUrl}/course/project/all?courseId=${courseId}`, {
          headers: headers,
        })
        .then(res => {
          setProjects(res.data.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleProjectDelete = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const deleteProjectPromise = axios.delete(
      `${backendUrl}/course/project?projectId=${projectId}`,
      {
        headers: headers,
      }
    );

    try {
      toast.promise(deleteProjectPromise, {
        loading: {
          title: 'Deleting the project...',
          status: 'info',
          isClosable: false,
          position: 'top-right',
          containerStyle: {
            marginTop: '5rem',
          },
          duration: 1000,
        },
        success: {
          title: 'Project deleted successfully!',
          status: 'success',
          position: 'top-right',
          containerStyle: {
            marginTop: '5rem',
          },
          duration: 1000,
        },
        error: {
          title: 'Failed to delete the project!',
          status: 'error',
          position: 'top-right',
          containerStyle: {
            marginTop: '5rem',
          },
          duration: 1000,
        },
      });

      const res = await deleteProjectPromise;

      fetchAllProjects();
    } catch (err) {
      console.error(err);
    }
  };

  const getProject = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      await axios
        .get(`${backendUrl}/course/project?projectId=${id}`, {
          headers: headers,
        })

        .then(res => {
          setProjectData(res.data.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const startProject = async (courseId, projectId, index) => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios
        .post(
          `${backendUrl}/user/rs/project_submission/start?courseId=${courseId}&projectId=${projectId}`,
          null,
          {
            headers: headers,
          }
        )
        .then(res => {
          setCurrentIndex(currentIndex === index ? null : index);
          fetchUserScoreboard(userId, courseId);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const getProjectSubmission = async (projectId, courseId, userId) => {
    localStorage.setItem('CorId', courseId);
    localStorage.setItem('ProId', projectId);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      let apiUrl = `${backendUrl}/user/rs/project_submission/specific?`;
      if (courseId) {
        apiUrl += `courseId=${courseId}&`;
      }
      if (projectId) {
        apiUrl += `projectId=${projectId}&`;
      }
      if (userId) {
        apiUrl += `userId=${userId}&`;
      }
      // Remove the trailing '&' if any
      apiUrl = apiUrl.replace(/&$/, '');

      await axios
        .get(apiUrl, {
          headers: headers,
        })
        .then(res => {
          setSubmittedProject(res.data.data);

          naviagte('/admin/submited_projects');
        });
    } catch (err) {
      console.error(err);
    }
  };

  const giveProjectScores = async (id, postData) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      await axios
        .put(
          `${backendUrl}/user/rs/project_submission/review?submissionId=${id}`,
          postData,
          {
            headers: headers,
          }
        )

        .then(res => {
          console.log('giveProjectScores', res.data.data);
          // setProjectData(res.data.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (
      scorecard &&
      Array.isArray(scorecard.project) &&
      scorecard.project.length > 0
    ) {
      const filteredProjects = scorecard.project.filter(
        project =>
          project && project.project && project.project._id === projectId
      );

      filteredProjects.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      if (filteredProjects.length > 0) {
        const latestProject = filteredProjects[0];
        setFilteredProjects(filteredProjects);
        setLatestSubProjectId(latestProject ? latestProject._id : null);
      } else {
        setFilteredProjects([]);
        setLatestSubProjectId(null);
      }
    }
  }, [projectId, scorecard]);

  const submitProject = async (
    submittedProjectId,
    courseId,
    projectId,
    submissionLinks
  ) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const postData = {
      submissionLinks: submissionLinks,
    };

    const submitProjectPromise = axios.put(
      `${backendUrl}/user/rs/project_submission?submittedProjectId=${submittedProjectId}&courseId=${courseId}&projectId=${projectId}`,
      postData,
      {
        headers: headers,
      }
    );

    toast.promise(submitProjectPromise, {
      loading: {
        title: 'Submitting Project...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Project submitted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to submit project!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await submitProjectPromise;
      fetchUserScoreboard(userId, courseId);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        projectId,
        fetchAllProjects,
        setToken,
        setProjectId,
        handleProjectDelete,
        getProject,
        ProjectData,
        setCurrentIndex,
        currentIndex,
        startProject,
        getProjectSubmission,
        submitProject,
        latestSubProjectId,
        submittedProjects,
        giveProjectScores,
        filteredProjects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
