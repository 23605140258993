import { createContext, useContext, useState } from 'react';
import EnvContext from './EnvContext';
// import toast from 'react-hot-toast';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

const SyllabusContext = createContext();

export const SyllabusContextProvider = ({ children }) => {
  const toast = useToast();

  const { backendUrl } = useContext(EnvContext);
  const [syllabusData, setSyllabusData] = useState({});
  const [token, setToken] = useState(null);

  let loadingToast;
  const [syllabusId, setSyllabusId] = useState(null);

  const postSyllabus = async (values, courseName) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const postData = {
      courseName: courseName,
      chapters: values.chapters,
    };

    const postSyllabusPromise = axios.post(
      `${backendUrl}/course/syllabus`,
      postData,
      { headers: headers }
    );

    toast.promise(postSyllabusPromise, {
      loading: {
        title: 'Adding syllabus...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Syllabus added successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add syllabus!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await postSyllabusPromise;

      setSyllabusId(res.data.syllabus._id);
    } catch (err) {
      console.error('mentor error', err);
    }
  };

  const updateSyllabus = async (syllabusId, postData) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const updateSyllabusPromise = axios.put(
      `${backendUrl}/course/syllabus?syllabusId=${syllabusId}`,
      postData,
      { headers: headers }
    );

    toast.promise(updateSyllabusPromise, {
      loading: {
        title: 'Updating Syllabus...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Syllabus updated successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to update syllabus!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await updateSyllabusPromise;

      getSyllabus(syllabusId);
    } catch (err) {
      console.error('mentor error', err);
    }
  };

  const deleteSyllabus = async syllabusId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const deleteSyllabusPromise = axios.delete(
      `${backendUrl}/course/syllabus?syllabusId=${syllabusId}`,
      { headers: headers }
    );

    toast.promise(deleteSyllabusPromise, {
      loading: {
        title: 'Deleting Syllabus...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Syllabus deleted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to delete syllabus!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      await deleteSyllabusPromise;

      getSyllabus(syllabusId);
    } catch (err) {
      console.error('mentor error', err);
    }
  };

  const getSyllabus = async value => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/course/syllabus?syllabusId=${value}`, {
          headers: headers,
        })
        .then(res => {
          setSyllabusData(res.data.syllabus);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <SyllabusContext.Provider
      value={{
        deleteSyllabus,
        updateSyllabus,
        postSyllabus,
        syllabusId,
        setToken,
        getSyllabus,
        syllabusData,
        setSyllabusId,
      }}
    >
      {children}
    </SyllabusContext.Provider>
  );
};

export default SyllabusContext;
